import ls from "../services/localStorage";
import { types } from "../constants/constants";

export const postApiLogin = (attributes : { auth_credentials: { email: string, password: string } }): Promise<object> => {
  return fetch(`${types.API_URL}auth/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(attributes),
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    const result: object = await response.json()

    return result;
  });
};

export const getApiUsers = (attributes : any): Promise<object> => {
  const esc = encodeURIComponent;
  const query = Object.keys(attributes) ? '?' + Object.keys(attributes).map(k => `${esc(k)}=${esc(attributes[k])}`).join('&') : ''
  const token:(string | null) = ls.get("token");
  
  return fetch(`${types.API_URL}users${query}`, { 
    headers : { token : ( token !== null ? token : '') } 
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    const result: object = await response.json()

    return result;
  })
};

export const getApiUser = (attributes : any): Promise<object> => {
  const esc = encodeURIComponent;
  const token:(string | null) = ls.get("token");
  
  return fetch(`${types.API_URL}users/id/${attributes._id}`, { 
    headers : { token : ( token !== null ? token : '') } 
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    const result: object = await response.json()

    return result;
  })
};

export const getApiRoles = (): Promise<object> => {
  const esc = encodeURIComponent;
  const token:(string | null) = ls.get("token");
  
  return fetch(`${types.API_URL}roles`, { 
    headers : { token : ( token !== null ? token : '') } 
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    const result: object = await response.json()

    return result;
  })
};

export const putApiUpdateUser = (attributes: any) => {
  const token:(string | null) = ls.get("token");

  return fetch(`${types.API_URL}users/update/id/${attributes.user._id}`, {
    method: "PUT",
    headers: {
      token : ( token !== null ? token : ''),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(attributes),
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.json()
  });
  
};