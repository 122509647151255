import "./Table.scss";

import React, { useState } from 'react';
import { TableProps } from "./TableTypes";
import Icon from "../Icon/Icon";


const Table = ( TableProps: TableProps ): React.ReactElement =>{
  const [sortDirection, setSortDirection] = useState<number>(1);
  const [sortBy, setSortBy] = useState<string>('created_at');

  const doSortBy = (field: string): void =>{
    setSortBy(field);
    setSortDirection(sortDirection === 1 ? -1 : 1)
    TableProps.doSortBy && TableProps.doSortBy(field)
  }

  const renderHeaders = (items: typeof TableProps.items) =>{
    return (
      <>
        { items[0].map((item, itemIndex) => (
          <>
            { item.name !== 'Id' && 
              <>
                { item.sortable ? 
                  <th key={itemIndex} scope="col" className="text-capitalize pointer" onClick={ () => TableProps.doSortBy && item.index && doSortBy(item.index) }>
                    <div className="header_text">
                      {item.name}
                      { item.sortable &&
                        <span className="sort_icons ms-2">
                          <Icon
                            name="chevronUp"
                            size="xsmall"
                            color={ item.index === sortBy && sortDirection === 1 ? 'principal' : 'secondary-4' } />
                          <Icon
                            name="chevronDown"
                            size="xsmall"
                            color={ item.index === sortBy && sortDirection === -1 ? 'principal' : 'secondary-4' } />
                        </span>
                      }
                    </div>
                  </th> 
                :
                  <th key={itemIndex} scope="col" className="text-capitalize">
                    <div className="header_text">
                      {item.name}
                    </div>
                  </th>                 
                }
              </>
            }
          </>
        ))}

        { TableProps.actions && 
          <th key={'actions'} scope="col" className="text-capitalize">{' '}</th> 
        }
      </>
    );
  }

  const renderItems = (items: typeof TableProps.items) =>{
    return items.map((row, rowIndex) => {
      let itemId: string = '';
      
      return ( 
        <tr key={rowIndex}>
          <>
            { row.map((item, itemIndex) => {
              if(item.name !== 'Id') {
                return (
                  <td key={item.name} className={item.className !== '' ? item.className : ''}>{ item.value }</td>
                )
              }else { 
                itemId = item.value;           
              }
            })}

            <>
              { TableProps.actions ?
              <td className="text-center">
                { TableProps.actions.map( ( action, index ) => (
                  <Icon
                    key={index}
                    name={action.icon}
                    size="small"
                    color="secondary-1"
                    className="me-2"
                    onClick={ () => action.onClick(itemId) }
                  />
                ) ) }
              </td>
              : null }
            </>
          </>
        </tr> 
      );
    });    
  }

  return(
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            { renderHeaders(TableProps.items) }
          </tr>
        </thead>
        <tbody>
          { renderItems(TableProps.items) }
        </tbody>
      </table>
    </div>
  )
}

export default Table;