import ls from "../services/localStorage";
import { types } from "../constants/constants";

export const getApiCourses = (attributes : any): Promise<object> => {
  const esc = encodeURIComponent;
  const query = Object.keys(attributes) ? '?' + Object.keys(attributes).map(k => `${esc(k)}=${esc(attributes[k])}`).join('&') : ''
  const token:(string | null) = ls.get("token");
  
  return fetch(`${types.API_URL}courses${query}`, { 
    headers : { token : ( token !== null ? token : '') } 
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    const result: object = await response.json()

    return result;
  })
};

export const getApiCourse = (attributes : any): Promise<object> => {
  const esc = encodeURIComponent;
  const token:(string | null) = ls.get("token");
  
  return fetch(`${types.API_URL}courses/id/${attributes._id}`, { 
    headers : { token : ( token !== null ? token : '') } 
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    const result: object = await response.json()

    return result;
  })
};

export const postApiCreateCourse = (attributes: any) => {
  const token:(string | null) = ls.get("token");

  return fetch(`${types.API_URL}course/create`, {
    method: "POST",
    headers: {
      token : ( token !== null ? token : ''),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(attributes),
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.json()
  }); 
};

export const putApiUpdateCourse = (attributes: any) => {
  const token:(string | null) = ls.get("token");

  return fetch(`${types.API_URL}courses/update/id/${attributes.course._id}`, {
    method: "PUT",
    headers: {
      token : ( token !== null ? token : ''),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(attributes),
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.json()
  }); 
};