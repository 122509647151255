import "./UserData.scss";

import React, { useEffect, useState } from 'react';
import { UserDataProps, UserProps } from "./UserDataTypes";
import InputText from "../../InputText/InputText";
import Select from "../../Select/Select";
import Button from "../../Button/Button";
import { getApiRoles, putApiUpdateUser } from "../../../services/apiUser";


const UserData = ( UserDataProps: UserDataProps ): React.ReactElement =>{
  const [saving, setSaving] = useState<boolean>(false);
  const [user, setUser] = useState<UserProps>(UserDataProps.user);
  const [userRoles, setUserRoles] = useState<{index: string, value: string}[]>([]);

  useEffect(() => {
    getRoles()
  }, []);

  const getRoles = async (): Promise<void> => {
    const result: object = getApiRoles()
    .then(async (response: any) => {
      const resultsUserRoles: { index: string, value: string }[] = await Promise.all(response && response.map((role: any, index: number) =>{
        return {
          index: role._id,
          value: role.tag
        }
      }));
      setUserRoles(resultsUserRoles);
    })
    .catch((error: any) => {
      console.log(error.message);
    });
  }

  const saveUserData = async(): Promise<void> =>{
    setSaving(true)
    
    const result: object = putApiUpdateUser({
      user : {
        _id: user._id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        roleId: user.role && user.role._id ? user.role._id : user.role
      }
    })
    .then(async (response: any) => {
      setSaving(false)

    })
    .catch((error: any) => {
      console.log(error.message);
    });
  }

  return(
    <React.Fragment>
      <div className="row mb-5">
        <div className="col-md-6 my-2">
          <InputText
            label="Nombre"
            value={user.firstName}
            onChange={(event: any) => { setUser({ ...user, firstName: event.currentTarget.value }) }} />
        </div>

        <div className="col-md-6 my-2">
          <InputText 
            label="Apellidos"
            value={user.lastName}
            onChange={(event: any) => { setUser({ ...user, lastName: event.currentTarget.value }) }} />       
        </div>

        <div className="col-md-6 my-2">
          <InputText
            label="Email"
            value={user.email}
            onChange={(event: any) => { setUser({ ...user, email: event.currentTarget.value }) }} />
        </div>

        <div className="col-md-6 my-2">
          <Select
            label="Rol"
            options={userRoles}
            defaultValue="Selecciona el rol"
            selectedValue={user && user.role && user.role._id}
            onSelect={(event: any) => { setUser({ ...user, role: event.currentTarget.value }) }} />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <Button value="Guardar" className="btn-orange mx-auto" onClick={saveUserData} iconRight={ saving ? 'loaderIcon' : '' } />
        </div>
      </div>
    </React.Fragment>
  )
}

export default UserData;