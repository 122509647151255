import "./Clientes.scss";

import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Menu from "../../components/Menu/Menu";
import { MenuProps } from "../../components/Menu/MenuTypes";
import { defaultMenuProps } from "../../constants/constants";
import { getApiUsers } from "../../services/apiUser";
import { types } from "../../constants/constants";
import { TableProps } from "../../components/Table/TableTypes";
import Table from "../../components/Table/Table";
import Button from "../../components/Button/Button";
import dayjs from "dayjs";
import 'dayjs/locale/es' // import locale


const Clientes = (): React.ReactElement =>{  
  const navigate = useNavigate();
  const [menuProps, setMenuProps] = useState<MenuProps>(defaultMenuProps);
  const [users, setUsers] = useState<TableProps['items']>([]);
  const [limit, setLimit] = useState<number>(types.PAGE_SIZE);
  const [offset, setOffset] = useState<number>(0);
  const [totalUsers, setTotalUsers] = useState<number>(0);
  const [actions, setActions] = useState<TableProps["actions"]>([]);
  const [sortDirection, setSortDirection] = useState<number>(-1);
  const [sortBy, setSortBy] = useState<string>('created_at');

  useEffect(() => {
    getUsers();

    setActions([
      /*{
        name : 'Editar',
        onClick : editAction,
        icon : 'pencil' 
      },
      {
        name : 'Borrar',
        onClick : deleteAction,
        icon : 'deleteIcon' 
      }*/
    ])
  }, []);

  useEffect(() => {
    getUsers();
  }, [sortBy, sortDirection]);

  const getUsers = async (): Promise<void> => {
    const result: object = getApiUsers(
      {
        limit: limit,
        offset: offset,
        sortBy: sortBy,
        sortDirection: sortDirection
      }
    ).then(async (response: any) => {
      setTotalUsers(response.total)

      const resultsUsers: TableProps['items'] = await Promise.all(response.users && response.users.map((user: any, index: number) =>{
        return [
          { name: '#', value : index + 1 },
          { name: 'Id', value : user._id },
          { name: 'Nombre', value : user.firstName + ' ' + user.lastName, sortable: true, index: 'firstName' },
          { name: 'Email', value : user.email, sortable: true, index: 'email' },
          { name: 'Nif/Cif', value : user.nif ? user.nif : '--', sortable: true, index: 'nif' },
          { name: 'Teléfono', value : user.phone ? user.phone : '--', sortable: true, index: 'phone', className: 'text-center' },
          { name: 'Dirección', value : user.address.address ? user.address.address : '--', sortable: false, index: 'address' },
          { name: 'CP', value : user.address.postalcode ? user.address.postalcode : '--', sortable: true, index: 'cp', className: 'text-center' },
          { name: 'Rol', value : user.role.tag, className: 'text-center' },
          { name: 'Registro', value : dayjs(user.created_at).format('DD/MM/YYYY'), sortable: true, index: 'created_at', className: 'text-center' },
          { name: 'Estado', value : user.state ? 'Activo' : 'Inactivo', sortable: true, index: 'state', className: 'text-center' }
        ]
      }));
      setUsers(resultsUsers);
    })
    .catch((error: any) => {
      console.log(error.message);
      setUsers([])
    });
  }

  const loadMoreUsers = async (): Promise<void> => {
    const offsetPage = offset + limit;
    
    const result: object = getApiUsers(
      {
        limit: limit,
        offset: offsetPage,
        sortBy: sortBy,
        sortDirection: sortDirection
      }
    ).then(async (response: any) => {
      const resultsUsers: TableProps['items'] = await Promise.all(response.users && response.users.map((user: any, index: number) =>{
        return [
          { name: '#', value : index + offsetPage + 1 },
          { name: 'Id', value : user._id },
          { name: 'Nombre', value : user.firstName + ' ' + user.lastName, sortable: true, index: 'firstName' },
          { name: 'Email', value : user.email, sortable: true, index: 'email' },
          { name: 'Nif/Cif', value : user.nif ? user.nif : '--', sortable: true, index: 'nif' },
          { name: 'Teléfono', value : user.phone ? user.phone : '--', sortable: true, index: 'phone', className: 'text-center' },
          { name: 'Dirección', value : user.address.address ? user.address.address : '--', sortable: true, index: 'address' },
          { name: 'CP', value : user.address.postalcode ? user.address.postalcode : '--', sortable: true, index: 'cp', className: 'text-center' },
          { name: 'Rol', value : user.role.tag, className: 'text-center' },
          { name: 'Registro', value : dayjs(user.created_at).format('DD/MM/YYYY'), sortable: true, index: 'created_at', className: 'text-center' },
          { name: 'Estado', value : user.state ? 'Activo' : 'Inactivo', sortable: true, index: 'state', className: 'text-center' }
        ]
      }));
      
      const arrayUsers = users.concat(resultsUsers)
      setOffset(offsetPage);
      setUsers(arrayUsers);
    })
    .catch((error: any) => {
      console.log(error.message);
      setUsers([])
    });
  }

  const editAction = (id: string): void => {
    navigate(`/clientes/${ id }`);
  }

  const deleteAction = (id: string): void => {
    alert('delete');
  }

  const doSortBy = (field : string): void => {
    setOffset(0)
    setSortBy(field)
    setSortDirection(sortDirection === 1 ? -1 : 1)
  }
  
  return(
    <React.Fragment>
      <div id="menu">
        <Menu items={ menuProps.items } />
      </div>

      <div id="app-content">
        <header className="table-header mb-4">
          <h1>Clientes</h1>
          <span>Total registros: { totalUsers }</span>
        </header>
        { users && users.length ?
          <Table items={ users } actions={actions} doSortBy={doSortBy} />
        :
          <p>No hay usuarios</p>
        }

        { totalUsers > users.length ?
          <div className="row mt-4">
            <div className="col-12 col-md-4 offset-md-4">
              <Button className={'btn-white m-auto'} value={'Cargar más'} onClick={ () => { loadMoreUsers() } } />
            </div>
          </div>
        : null }
      </div>
    </React.Fragment>
  )
}

export default Clientes;