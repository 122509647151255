
const getSize = (size: number) => {
  var sizes = [' Bytes', ' KB', ' MB', ' GB',
              ' TB', ' PB', ' EB', ' ZB', ' YB'];
    
  for (var i = 1; i < sizes.length; i++) {
      if (size < Math.pow(1024, i))
      return (Math.round((size / Math.pow(
          1024, i - 1)) * 100) / 100) + sizes[i - 1];
  }
  return size;
}

const isURLValid = (str: string) => {
  try {
    const newUrl = new URL(str);
    return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
  } catch (err) {
    return false;
  }
}

const objectToExport = {
  getSize: getSize,
  isURLValid : isURLValid,
};

export default objectToExport;