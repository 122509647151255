import "./Menu.scss";

import React from 'react';
import { MenuProps, MenuItem } from './MenuTypes';
import logo from "../../assets/images/logo-silla.svg";
import { useLocation, useNavigate } from "react-router-dom";
import ls from "../../services/localStorage";

const Menu = ( menuProps : MenuProps): React.ReactElement =>{
  const location = useLocation();
  const navigate = useNavigate();

  const logout = (): void =>{
    ls.remove('token');
    navigate('/');
  }

  return(
    <React.Fragment>
      <div className="menu-wrapper">
        <div className="menu-items">
          <img src={logo} className="logo" />
          { menuProps.items && menuProps.items.map((item: MenuItem) =>
            <p key={item.title}>
              <a href={item.link} className={item.link === location.pathname ? 'active' : ''}>
                { item.title }
              </a>
            </p>
          ) }
        </div>

        <a href="#" onClick={() => { logout() }}>
          Cerrar Sesión
        </a>
      </div>
    </React.Fragment>
  )
}

export default Menu;