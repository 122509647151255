import React from "react";
import "./UploadPicture.scss";
import { UploadPictureDataProps } from "./UploadPictureData";
import Form from "react-bootstrap/Form";
import Icon from "../Icon/Icon";
import utils from "../../services/utils";
import Button from "../Button/Button";

function shortenFilename(filename: string, N: number) {
  if (filename.length <= N) {
    return filename;
  } else {
    const headLength = Math.floor((N - 3) / 2);
    const tailLength = N - 3 - headLength;
    return filename.substr(0, headLength) + "..." + filename.substr(-tailLength);
  }
}
const UploadPicture = ( UploadPictureDataProps: UploadPictureDataProps ): React.ReactElement =>{
  const onChangeFile = (event : any) => {
    UploadPictureDataProps.onChange({
      ...UploadPictureDataProps.item,
      picture: event.target.files,
    });
  };

  const inputCoverFile = React.useRef<any>();

  return (
    <section className="upload-picture">
      { UploadPictureDataProps.item && ( UploadPictureDataProps.item.picture_big || UploadPictureDataProps.item.picture) ? (
        <React.Fragment>
          <div className="upload-picture__cover-preview">
            <img
              src={
                UploadPictureDataProps.item.picture
                  ? URL.createObjectURL(UploadPictureDataProps.item.picture[0])
                  : UploadPictureDataProps.item.picture_big
              }
            />
          </div>
          <div className="upload-picture__cover-info">
            { UploadPictureDataProps.item.picture &&
              <h3 className="upload-book__title ellipsis white">
                { shortenFilename(UploadPictureDataProps.item.picture[0].name, 20)}
              </h3>
            }
            { UploadPictureDataProps.item.picture && 
              <p className="upload-book__text white">
                { utils.getSize(UploadPictureDataProps.item.picture[0].size)}
              </p>
            }
            <Form.Group controlId="formFile" className="mb-3 upload-upload">
              <Form.Control
                type="file"
                className="upload-upload"
                accept="image/png, image/jpeg"
                onChange={onChangeFile}
                style={{ display: "none" }}
                ref={inputCoverFile}
              />
            </Form.Group>
            <div className="upload-picture__action">
              <Button
                className="btn-white-transparent"
                value="Cambiar imagen"
                onClick={() => {
                  inputCoverFile.current.click();
                }}
              />
            </div>
          </div>


        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="upload-picture__icon">
            <Icon name="file" size="medium" color="secondary-1" />
          </div>

          <div className="upload-picture__content">

            <h3 className="upload-picture__title">Sube una imagen</h3>
            <p className="upload-picture__text">Formato JPG/PNG. Max 5MB</p>
            {UploadPictureDataProps.item && UploadPictureDataProps.item.error && UploadPictureDataProps.item.error.cover ? (
              <p className="upload-picture__text-error">{UploadPictureDataProps.item.error.cover}</p>
            ) : null}
            <Form.Group controlId="formFile" className="mb-3 upload-upload">
              <Form.Control
                type="file"
                className="upload-upload"
                accept="image/png, image/jpeg"
                onChange={onChangeFile}
                style={{ display: "none" }}
                ref={inputCoverFile}
              />
            </Form.Group>
            <div className="upload-picture__action">
              <Button
                className="btn-white"
                value="Examinar"
                onClick={() => {
                  inputCoverFile.current.click();
                }}
              />
            </div>
          </div>
        </React.Fragment>
      )}

    </section>
  );
}

export default UploadPicture;
