import "./CrearCurso.scss";

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Menu from "../../components/Menu/Menu";
import { MenuProps } from "../../components/Menu/MenuTypes";
import { defaultMenuProps } from "../../constants/constants";
import CourseData from "../../components/Course/CourseData/CourseData";
import { getApiUser } from "../../services/apiUser";
import { types } from "../../constants/constants";
import Button from "../../components/Button/Button";
import dayjs from "dayjs";
import 'dayjs/locale/es' // import locale
import { CourseProps, CourseDataProps } from "../../components/Course/CourseData/CourseDataTypes";

const CrearCurso = (): React.ReactElement =>{  
  const navigate = useNavigate();
  const [menuProps, setMenuProps] = useState<MenuProps>(defaultMenuProps);
  const [courseData, setCourseData] = useState<CourseDataProps>({course:{ _id: '' }});
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    setLoaded(true)
  }, []);
  
  return(
    <React.Fragment>
      <div id="menu">
        <Menu items={ menuProps.items } />
      </div>

      <div id="app-content">
        <header className="table-header mb-4">
          <h1>Crear curso</h1>
          <span></span>
        </header>

        { loaded && 
          <CourseData course={courseData.course} />
        }
      </div>
    </React.Fragment>
  )
}

export default CrearCurso;