import "./InputText.scss";

import React, { useState } from "react";
import { InputProps } from "./InputProps";

function InputText(props: InputProps) {
  const [value, setValue] = useState<string>(props.value ? props.value : '');

  const handleChange = (event: any): void => {
    props.onChange(event)
    setValue(event.currentTarget.value)
  }

  return(
    <div>
      { props.label && <label>{props.label}</label> }
      <input value={value} type={props.type ? props.type : 'text'} onChange={handleChange} placeholder={props.placeholder ? props.placeholder : ''} />
    </div>
  );
}

export default InputText