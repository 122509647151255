import jwt_decode from "jwt-decode";

const decode = (token: string): (object | null) => {
  try{
    const tokenDecoded: { user: object, iat: number, exp: number } = jwt_decode(token);
    return tokenDecoded;
  } catch(e:any) {
    console.log(e.message)
    return null
  }
};

const validate = (token: string): boolean => {
  try{
    const tokenDecoded: { user: object, iat: number, exp: number } = jwt_decode(token)
    return (Date.now() < tokenDecoded.exp * 1000)
  } catch(e: any) {
    console.log('Error validate token: ' + e.message + ' for token: ' + token)
    return false
  }
}

const getUser = (token: string): ({ _id: string, nickName: string, role: string, email: string, isRefreshing: boolean} | null) => {
  try{
    const tokenDecoded: { user: { _id: string, nickName: string, role: string, email: string, isRefreshing: boolean} , iat: number, exp: number } = jwt_decode(token)
    return tokenDecoded.user
  } catch(e: any) {
    console.log('Error validate token: ' + e.message + ' for token: ' + token)
    return null
  }
}

const objectToExport = {
  decode: decode,
  validate: validate,
  getUser: getUser
};

export default objectToExport;
