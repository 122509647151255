import "./Libros.scss";

import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Menu from "../../components/Menu/Menu";
import { MenuProps } from "../../components/Menu/MenuTypes";
import { defaultMenuProps } from "../../constants/constants";
import { getApiBooks } from "../../services/apiBook";
import { types } from "../../constants/constants";
import { TableProps } from "../../components/Table/TableTypes";
import Table from "../../components/Table/Table";
import Button from "../../components/Button/Button";
import dayjs from "dayjs";
import 'dayjs/locale/es' // import locale


const Libros = (): React.ReactElement =>{  
  const navigate = useNavigate();
  const [menuProps, setMenuProps] = useState<MenuProps>(defaultMenuProps);
  const [books, setBooks] = useState<TableProps['items']>([]);
  const [limit, setLimit] = useState<number>(types.PAGE_SIZE);
  const [offset, setOffset] = useState<number>(0);
  const [totalBooks, setTotalBooks] = useState<number>(0);
  const [actions, setActions] = useState<TableProps["actions"]>([]);
  const [sortDirection, setSortDirection] = useState<number>(1);
  const [sortBy, setSortBy] = useState<string>('created_at');

  useEffect(() => {
    getBooks();

    setActions([
      /*{
        name : 'Editar',
        onClick : editAction,
        icon : 'pencil' 
      },
      {
        name : 'Borrar',
        onClick : deleteAction,
        icon : 'deleteIcon' 
      }*/
    ])
  }, []);

  useEffect(() => {
    getBooks();
  }, [sortBy, sortDirection]);

  const getBooks = async (): Promise<void> => {
    const result: object = getApiBooks(
      {
        limit: limit,
        offset: offset,
        sortBy: sortBy,
        sortDirection: sortDirection
      }
    ).then(async (response: any) => {
      setTotalBooks(response.total)

      const resultsBooks: TableProps['items'] = await Promise.all(response.books && response.books.map((book: any, index: number) =>{
        return [
          { name: '#', value : index + 1 },
          { name: 'Id', value : book._id },
          { name: 'Título', value : book.title, sortable: true, index: 'title' },
          { name: 'Autor', value : `${book.user.firstName} ${book.user.lastName}`},
          { name: 'ISBN', value : `${book.isbn}`},
          { name: 'Concurso', value : `${book.isbn_promo ? true : false}`, sortable: true, index: 'isbn_promo', className: 'text-center'},
          { name: 'Precio', value : book.price ? `${book.price.toString().replace('.', ',')}€` : '--', sortable: true, index: 'price', className: 'text-center' },
          { name: 'Ventas', value : book.sales, className: 'text-center' },
          { name: 'Visto', value : book.views, sortable: true, index: 'views', className: 'text-center' },
          { name: 'Publicado', value : dayjs(book.created_at).format('DD/MM/YYYY'), sortable: true, index: 'created_at', className: 'text-center' }
        ]
      }));
      setBooks(resultsBooks);
    })
    .catch((error: any) => {
      console.log(error.message);
      setBooks([])
    });
  }

  const loadMoreBooks = async (): Promise<void> => {
    const offsetPage = offset + limit;
    
    const result: object = getApiBooks(
      {
        limit: limit,
        offset: offsetPage,
        sortBy: sortBy,
        sortDirection: sortDirection
      }
    ).then(async (response: any) => {
      const resultsBooks: TableProps['items'] = await Promise.all(response.books && response.books.map((book: any, index: number) =>{
        return [
          { name: '#', value : index + offsetPage + 1 },
          { name: 'Id', value : book._id },
          { name: 'Título', value : book.title, sortable: true, index: 'title' },
          { name: 'Autor', value : `${book.user.firstName} ${book.user.lastName}`},
          { name: 'ISBN', value : `${book.isbn}`},
          { name: 'Concurso', value : `${book.isbn_promo ? true : false}`, sortable: true, index: 'isbn_promo', className: 'text-center'},
          { name: 'Precio', value : book.price ? `${book.price.toString().replace('.', ',')}€` : '--', sortable: true, index: 'price', className: 'text-center' },
          { name: 'Ventas', value : book.sales, className: 'text-center' },
          { name: 'Visto', value : book.views, sortable: true, index: 'views', className: 'text-center' },
          { name: 'Publicado', value : dayjs(book.created_at).format('DD/MM/YYYY'), sortable: true, index: 'created_at', className: 'text-center' }
        ]
      }));
      
      const arrayBooks = books.concat(resultsBooks)
      setOffset(offsetPage);
      setBooks(arrayBooks);
    })
    .catch((error: any) => {
      console.log(error.message);
      setBooks([])
    });
  }

  const editAction = (id: string): void => {
    navigate(`/libros/${ id }`);
  }

  const deleteAction = (id: string): void => {
    alert('delete');
  }

  const doSortBy = (field : string): void => {
    setOffset(0)
    setSortBy(field)
    setSortDirection(sortDirection === 1 ? -1 : 1)
  }
  
  return(
    <React.Fragment>
      <div id="menu">
        <Menu items={ menuProps.items } />
      </div>

      <div id="app-content">
        <header className="table-header mb-4">
          <h1>Libros</h1>
          <span>Total registros: { totalBooks }</span>
        </header>
        { books && books.length ?
          <Table items={ books } actions={actions} doSortBy={doSortBy} />
        :
          <p>No hay libros</p>
        }

        { totalBooks > books.length ?
          <div className="row mt-4">
            <div className="col-12 col-md-4 offset-md-4">
              <Button className={'btn-white m-auto'} value={'Cargar más'} onClick={ () => { loadMoreBooks() } } />
            </div>
          </div>
        : null }
      </div>
    </React.Fragment>
  )
}

export default Libros;