import Icon from "../Icon/Icon";
import "./Button.scss";

import React, { ButtonHTMLAttributes } from "react";

interface buttonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  value ?: string,
  iconRight ?: string,
}

const Button: React.FC<buttonProps> = ({ value, iconRight, ...rest }) => {
  return(
    <button { ...rest } type="button" className={ `btn ${ rest.className ? rest.className : '' }` }>
      
      {value}

      { iconRight ?
        <Icon name={iconRight} className={"btn-icon right"} size={""} color={""} />
      : null }
      
    </button>
  );
}

export default Button