import "./EditarCurso.scss";

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Menu from "../../components/Menu/Menu";
import { MenuProps } from "../../components/Menu/MenuTypes";
import { defaultMenuProps } from "../../constants/constants";
import CourseData from "../../components/Course/CourseData/CourseData";
import { getApiCourse } from "../../services/apiCourse";
import { types } from "../../constants/constants";
import Button from "../../components/Button/Button";
import dayjs from "dayjs";
import 'dayjs/locale/es' // import locale
import { CourseProps, CourseDataProps } from "../../components/Course/CourseData/CourseDataTypes";

const EditarCurso = (): React.ReactElement =>{  
  const navigate = useNavigate();
  const urlParams = useParams();
  const [menuProps, setMenuProps] = useState<MenuProps>(defaultMenuProps);
  const [courseData, setCourseData] = useState<CourseDataProps>({course:{ _id: '' }});
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    getCourse({ _id: urlParams.cursoId})
  }, []);

  const getCourse = async (attributes: any): Promise<void> => {
    const result: object = getApiCourse(
      {
        _id: attributes._id
      }
    ).then(async (response: any) => {
      const userCategories: string[] = await Promise.all(response.categories.map((category: any) => { return category.tag }));

      setCourseData({
        course: response
      })

      setLoaded(true)
    })
    .catch((error: any) => {
      console.log(error.message);
    });
  }
  
  return(
    <React.Fragment>
      <div id="menu">
        <Menu items={ menuProps.items } />
      </div>

      <div id="app-content">
        <header className="table-header mb-4">
          <h1>Editar curso</h1>
          <span></span>
        </header>

        { loaded && 
          <CourseData course={ courseData.course } />
        }
      </div>
    </React.Fragment>
  )
}

export default EditarCurso;