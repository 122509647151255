import ls from "../services/localStorage";
import { types } from "../constants/constants";

export const getApiSales = (attributes : any): Promise<object> => {
  const esc = encodeURIComponent;
  const query = Object.keys(attributes) ? '?' + Object.keys(attributes).map(k => `${esc(k)}=${esc(attributes[k])}`).join('&') : ''
  const token:(string | null) = ls.get("token");
  
  return fetch(`${types.API_URL}sales${query}`, { 
    headers : { token : ( token !== null ? token : '') } 
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    const result: object = await response.json()

    return result;
  })
};

export const getApiSalesCourses = (attributes : any): Promise<object> => {
  const esc = encodeURIComponent;
  const query = Object.keys(attributes) ? '?' + Object.keys(attributes).map(k => `${esc(k)}=${esc(attributes[k])}`).join('&') : ''
  const token:(string | null) = ls.get("token");
  
  return fetch(`${types.API_URL}sales/courses${query}`, { 
    headers : { token : ( token !== null ? token : '') } 
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    const result: object = await response.json()

    return result;
  })
};