import "./Select.scss";

import React from "react";
import Icon from "../Icon/Icon";

interface selectProps{
  onSelect: Function,
  selectedValue ?: string,
  defaultValue ?: string,
  error ?: string,
  active ?: boolean,
  label ?: string,
  options ?: Array<any>
}

const Select: React.FC<selectProps> = ({ onSelect, selectedValue, defaultValue, error, active, label, options, ...rest }) => {
  const selectClasses = `select-container__select ${error ? "error" : ""} ${
    active ? "active" : ""
  }`;

  return (
    <React.Fragment>
      { label && <label>{label}</label> }

      <div className="select-container">
        <select {...rest} className={selectClasses} onChange={(event => { onSelect(event) })}>
          { defaultValue ?
            <option className="select-container__select-option" value="" key={'default-option'}>{defaultValue}</option>
          : null }
          { options ? 
            <React.Fragment>
              { options.map((option: any) => (
                <React.Fragment>
                  { selectedValue && selectedValue === option.index ?
                    <option className="select-container__select-option" value={option.index} key={option.index} selected>
                      { option.value }
                    </option>
                  :
                    <option className="select-container__select-option" value={option.index} key={option.index}>
                      { option.value }
                    </option>
                  }
                </React.Fragment>
              ))}
            </React.Fragment>
          : null }
        </select>

        <Icon name='chevronDown' size='small' color='secondary-3' className='select-container__select-caret' />
      </div>
    </React.Fragment>
  );
};

export default Select;
