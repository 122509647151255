import "./CourseData.scss";

import React, { useEffect, useState } from 'react';
import { CourseDataProps, CourseProps } from "./CourseDataTypes";
import { postApiCreateCourse, putApiUpdateCourse } from "../../../services/apiCourse";
import ls from "../../../services/localStorage";
import InputText from "../../InputText/InputText";
import Select from "../../Select/Select";
import Button from "../../Button/Button";
import { 
  BtnBold,
  BtnBulletList,
  BtnItalic,
  BtnUnderline,
  Editor,
  EditorProvider,
  Toolbar
} from 'react-simple-wysiwyg';
import UploadPicture from "../../UploadPicture/UploadPicture";
import { types } from "../../../constants/constants";
import CheckBox from "../../CheckBox/CheckBox";
import { useNavigate } from "react-router-dom";


const CourseData = ( CourseDataProps: CourseDataProps ): React.ReactElement =>{
  const navigate = useNavigate();
  const [saving, setSaving] = useState<boolean>(false);
  const [course, setCourse] = useState<CourseProps>(CourseDataProps.course);
  const [selectedCategories, setSelectedCategories] = useState<string[]>(CourseDataProps.course && CourseDataProps.course.categories ? CourseDataProps.course.categories : []);
  const [formErrors, setFormErrors] = useState<any>();

  useEffect(() => {
  }, []);

  const saveCourseData = async(): Promise<void> =>{
    setSaving(true)

    try{
      let error = false;
      setFormErrors((formErrors: any) => Object.assign({}));

      if (!course.title) {
        setFormErrors((formErrors: any) => Object.assign({}, formErrors, { title: "El título es obligatorio" }));
        error = true;
      }

      if (!course.teacher) {
        setFormErrors((formErrors: any) => Object.assign({}, formErrors, { teacher : "El profesor es obligatorio" }));
        error = true;
      }

      if (!course.type) {
        setFormErrors((formErrors: any) => Object.assign({}, formErrors, { type : "El tipo de curso es obligatorio" }));
        error = true;
      }

      if ((!course._id && !course.picture) || (course._id && (!course.picture_big && !course.picture))) {
        setFormErrors((formErrors: any) => Object.assign({}, formErrors, { picture : "La foto es obligatoria" }));
        error = true;
      }

      if (course.type === 'sincrono' && !course.date) {
        setFormErrors((formErrors: any) => Object.assign({}, formErrors, { date : "Las fechas son obligatorias" }));
        error = true;
      }

      if (course.type === 'sincrono' && !course.days) {
        setFormErrors((formErrors: any) => Object.assign({}, formErrors, { days : "Los días son obligatorios" }));
        error = true;
      }

      if (!course.hours) {
        setFormErrors((formErrors: any) => Object.assign({}, formErrors, { hours : "Las horas son obligatorias" }));
        error = true;
      }

      if (!course.url) {
        setFormErrors((formErrors: any) => Object.assign({}, formErrors, { url : "La url es obligatoria" }));
        error = true;
      }

      if (!course.price) {
        setFormErrors((formErrors: any) => Object.assign({}, formErrors, { price : "El precio es obligatorio" }));
        error = true;
      }

      if (!course.description) {
        setFormErrors((formErrors: any) => Object.assign({}, formErrors, { description : "La descripción" }));
        error = true;
      }

      if (!course.content) {
        setFormErrors((formErrors: any) => Object.assign({}, formErrors, { content : "Los contenidos son obligatorios" }));
        error = true;
      }

      if (!course.area) {
        setFormErrors((formErrors: any) => Object.assign({}, formErrors, { area : "El área es obligatoria" }));
        error = true;
      }

      if (course.area && (!selectedCategories || selectedCategories.length === 0)) {
        setFormErrors((formErrors: any) => Object.assign({}, formErrors, { categories : "Elige al menos una categoría" }));
        error = true;
      }

      //setFormErrors(errors);

      if(!error){
        console.log(course)
        const token = ls.get("token");
        setFormErrors((formErrors: any) => Object.assign({}, formErrors, { form : "" }));

        let responsePictureUploadJson: any = {}

        if(course.picture){
          // Upload image file
          let formDataImage = new FormData()

          formDataImage.append("file", course && course.picture ? course.picture[0] : '')

          let responsePictureUpload = await fetch(`${types.API_URL}upload`, {
            method: "POST",
            headers : { token : token ? token : '' },
            body: formDataImage
          })

          if(!responsePictureUpload.ok){ alert(await responsePictureUpload.json()); }

          responsePictureUploadJson = await responsePictureUpload.json()
        }

        const attributes: any = {
          course: {
            title: course.title,
            teacher: course.teacher,
            type: course.type,
            price: course.price,
            date: course.date,
            days: course.days,
            hours: course.hours,
            frequency: (course.frequency ? course.frequency : 'totales'),
            url: course.url,
            description: course.description,
            content: course.content,
            information: course.information,
            area: course.area,
            categories: selectedCategories,
            picture_big: course.picture ? responsePictureUploadJson.url_big : course.picture_big,
            picture_medium: course.picture ? responsePictureUploadJson.url_medium : course.picture_medium,
            picture_thumb: course.picture ? responsePictureUploadJson.url_thumb : course.picture_thumb
          }
        }
      
        if(course._id && course._id !== '') {
          attributes.course._id = course._id
          const result: object = putApiUpdateCourse(attributes)
          .then(async (response: any) => {
            setSaving(false)
            navigate('/cursos')
          })
          .catch((error: any) => {
            setSaving(false)
            console.log(error.message);
          });
        } else {
          const result: object = postApiCreateCourse(attributes)
          .then(async (response: any) => {
            setSaving(false)
            navigate('/cursos')
          })
          .catch((error: any) => {
            setSaving(false)
            console.log(error.message);
          });
        }
      }else{
        setFormErrors((formErrors: any) => Object.assign({}, formErrors, { form : "Hay errores en el formulario" }));
        console.log(formErrors)
        setSaving(false)
      }
    }catch(error: any) {
      alert(error.stack);
    }
  }

  const handleCheckbox = (event: any) => {
    if (selectedCategories.find((g) => g === event.currentTarget.value)) {
      setSelectedCategories(selectedCategories.filter((g) => g !== event.currentTarget.value));
    } else {
      setSelectedCategories([...selectedCategories, event.currentTarget.value]);
    }
  }

  return(
    <React.Fragment>
      <div className="row mb-5">
        <div className="col-md-6 my-3">
          <InputText
            label="Nombre *"
            placeholder="Nombre del curso"
            value={course.title}
            onChange={(event: any) => { setCourse({ ...course, title: event.currentTarget.value }) }} />

          { formErrors && formErrors.title &&
            <p className="error">{ formErrors.title }</p>
          }
        </div>

        <div className="col-md-6 my-3">
          <InputText 
            label="Profesor *"
            placeholder="Profesor que lo imparte"
            value={course.teacher}
            onChange={(event: any) => { setCourse({ ...course, teacher: event.currentTarget.value }) }} /> 

          { formErrors && formErrors.teacher &&
            <p className="error">{ formErrors.teacher }</p>
          }      
        </div>

        <div className="col-12 my-3">
          <InputText 
            label="Url *"
            placeholder="Url de acceso al curso"
            value={course.url}
            onChange={(event: any) => { setCourse({ ...course, url: event.currentTarget.value }) }} /> 

          { formErrors && formErrors.url &&
            <p className="error">{ formErrors.url }</p>
          }      
        </div>

        <div className="col-12 my-3">
          <label className="mb-2">Imagen del curso *</label>
          <UploadPicture item={course} onChange={setCourse} /> 

          { formErrors && formErrors.picture &&
            <p className="error">{ formErrors.picture }</p>
          }  
        </div>

        <div className="col-md-3 my-3">
          <InputText
            label="Precio *"
            placeholder="10,50"
            type="number"
            value={course.price ? course.price.toString() : ''}
            onChange={(event: any) => { setCourse({ ...course, price: event.currentTarget.value }) }} /> 

          { formErrors && formErrors.price &&
            <p className="error">{ formErrors.price }</p>
          }          
        </div>

        <div className="col-md-2 my-3">
          <InputText
            type="number"
            label="Horas *"
            value={course.hours ? course.hours : ''}
            placeholder="8"
            onChange={(event: any) => { setCourse({ ...course, hours: event.currentTarget.value }) }} />

            { formErrors && formErrors.hours &&
              <p className="error">{ formErrors.hours }</p>
            }                
        </div>

        <div className="col-md-3 my-3">
          <Select
            label="Frecuencia*"
            options={[{index : 'totales', value : 'totales'}, {index: 'dia', value : 'al día'}, {index: 'semana', value : 'a la semana'}]}
            selectedValue={course && course.frequency ? course.frequency : 'totales' }
            onSelect={(event: any) => { setCourse({ ...course, frequency: event.currentTarget.value }) }} />

            { formErrors && formErrors.type &&
              <p className="error">{ formErrors.type }</p>
            }            
        </div>

        <div className="col-md-4 my-3">
          <Select
            label="Tipo de curso *"
            options={[{index : 'sincrono', value : 'Síncrono'}, {index: 'asincrono', value : 'Asíncrono'}]}
            defaultValue="Selecciona el tipo de curso"
            selectedValue={course && course.type }
            onSelect={(event: any) => { setCourse({ ...course, type: event.currentTarget.value }) }} />

            { formErrors && formErrors.type &&
              <p className="error">{ formErrors.type }</p>
            }            
        </div>

        { course.type === 'sincrono' && // If course is sincronous, load rest of properties
          <React.Fragment>
            <div className="col-md-4 my-3">
              <InputText
                label="Fechas *"
                value={course.date ? course.date : ''}
                placeholder="15 Jul 2023 / 25 Ago 2023"
                onChange={(event: any) => { setCourse({ ...course, date: event.currentTarget.value }) }} />

                { formErrors && formErrors.date &&
                  <p className="error">{ formErrors.date }</p>
                }                
            </div>

            <div className="col-md-4 my-3">
              <InputText
                label="Días *"
                value={course.days ? course.days : ''}
                placeholder="Martes y Jueves"
                onChange={(event: any) => { setCourse({ ...course, days: event.currentTarget.value }) }} />

                { formErrors && formErrors.days &&
                  <p className="error">{ formErrors.days }</p>
                }                
            </div>
          </React.Fragment>
        }

        <div className="col-md-12 my-3">
          <label className="mb-2">Descripción del curso *</label>
          <EditorProvider>
            <Editor value={course.description ? course.description.replaceAll('&lt;', '<') : ''} onChange={(event: any) => { setCourse({ ...course, description: event.target.value }) }}>
              <Toolbar>
                <BtnBold />
                <BtnItalic />
                <BtnUnderline />
                <BtnBulletList />
              </Toolbar>
            </Editor>
          </EditorProvider>

          { formErrors && formErrors.description &&
            <p className="error">{ formErrors.description }</p>
          }            
        </div>

        <div className="col-md-12 my-3">
          <label className="mb-2">Contenidos y temas *</label>
          <EditorProvider>
            <Editor value={course.content ? course.content.replaceAll('&lt;', '<') : ''} onChange={(event: any) => { setCourse({ ...course, content: event.target.value }) }}>
              <Toolbar>
                <BtnBold />
                <BtnItalic />
                <BtnUnderline />
                <BtnBulletList />
              </Toolbar>
            </Editor>
          </EditorProvider>

          { formErrors && formErrors.content &&
            <p className="error">{ formErrors.content }</p>
          }            
        </div>

        <div className="col-md-12 my-3">
          <label className="mb-2">Información relevante *</label>
          <EditorProvider>
            <Editor value={course.information ? course.information.replaceAll('&lt;', '<') : ''} onChange={(event: any) => { setCourse({ ...course, information: event.target.value }) }}>
              <Toolbar>
                <BtnBold />
                <BtnItalic />
                <BtnUnderline />
                <BtnBulletList />
              </Toolbar>
            </Editor>
          </EditorProvider>
        </div>

        <div className="col-md-12 my-3">
          <label className="mb-2">Selecciona el área y las categorías del curso *</label>
          
          <label className="searcher__filter-item mt-2">
            <input
              type="radio"
              name="area"
              value="tecnica"
              checked={course.area === 'tecnica'}
              onChange={(event: any) => { setSelectedCategories([]); setCourse({ ...course, area: event.target.value }) }}
            />
            Técnica de escritura
          </label>
          
          { course && course.area === 'tecnica' &&
            <div className="mt-1 ps-4">
              <CheckBox 
              label={'Escritura creativa'}
              name={'categories'} 
              value={'Escritura creativa'}
              checked={ selectedCategories && selectedCategories.indexOf('Escritura creativa') !== -1 }
              onChange={(event: any) => { handleCheckbox(event) }} />

              <CheckBox 
              label={'Personajes'}
              name={'categories'}
              value={'Personajes'}
              checked={ selectedCategories && selectedCategories.indexOf('Personajes') !== -1 }
              onChange={(event: any) => { handleCheckbox(event) }} />

              <CheckBox 
              label={'Diálogos'}
              name={'categories'}
              value={'Diálogos'}
              checked={ selectedCategories && selectedCategories.indexOf('Diálogos') !== -1 }
              onChange={(event: any) => { handleCheckbox(event) }} />

              <CheckBox 
              label={'Géneros'}
              name={'categories'}
              value={'Géneros'}
              checked={ selectedCategories && selectedCategories.indexOf('Géneros') !== -1 }
              onChange={(event: any) => { handleCheckbox(event) }} />

              <CheckBox 
              label={'Cine'}
              name={'categories'}
              value={'Cine'}
              checked={ selectedCategories && selectedCategories.indexOf('Cine') !== -1 }
              onChange={(event: any) => { handleCheckbox(event) }} />

              <CheckBox 
              label={'Estructuras narrativas'}
              name={'categories'}
              value={'Estructuras narrativas'}
              checked={ selectedCategories && selectedCategories.indexOf('Estructuras narrativas') !== -1 }
              onChange={(event: any) => { handleCheckbox(event) }} />

              <CheckBox 
              label={'No ficción'}
              name={'categories'}
              value={'No ficción'}
              checked={ selectedCategories && selectedCategories.indexOf('No ficción') !== -1 }
              onChange={(event: any) => { handleCheckbox(event) }} />
              
              <CheckBox 
              label={'Poesía'}
              name={'categories'}
              value={'Poesía'}
              checked={ selectedCategories && selectedCategories.indexOf('Poesía') !== -1 }
              onChange={(event: any) => { handleCheckbox(event) }} />
            </div>
          }

          <label className="searcher__filter-item mt-2">
            <input
              type="radio"
              name="area"
              value="despues"
              checked={course.area === 'despues'}
              onChange={(event: any) => { setSelectedCategories([]); setCourse({ ...course, area: event.target.value }) }}
            />
            Después del proceso de escritura
          </label>

          { course && course.area === 'despues' &&
            <div className="mt-1 ps-4">
              <CheckBox 
              label={'Maquetación'}
              name={'categories'} 
              value={'Maquetación'}
              checked={ selectedCategories && selectedCategories.indexOf('Maquetación') !== -1 }
              onChange={(event: any) => { handleCheckbox(event) }} />

              <CheckBox 
              label={'Marketing'}
              name={'categories'}
              value={'Marketing'}
              checked={ selectedCategories && selectedCategories.indexOf('Marketing') !== -1 }
              onChange={(event: any) => { handleCheckbox(event) }} />

              <CheckBox 
              label={'Ventas'}
              name={'categories'}
              value={'Ventas'}
              checked={ selectedCategories && selectedCategories.indexOf('Ventas') !== -1 }
              onChange={(event: any) => { handleCheckbox(event) }} />

              <CheckBox 
              label={'Ilustración'}
              name={'categories'}
              value={'Ilustración'}
              checked={ selectedCategories && selectedCategories.indexOf('Ilustración') !== -1 }
              onChange={(event: any) => { handleCheckbox(event) }} />

              <CheckBox 
              label={'Legal'}
              name={'categories'}
              value={'Legal'}
              checked={ selectedCategories && selectedCategories.indexOf('Legal') !== -1 }
              onChange={(event: any) => { handleCheckbox(event) }} />

              <CheckBox 
              label={'Diseño de portadas'}
              name={'categories'}
              value={'Diseño de portadas'}
              checked={ selectedCategories && selectedCategories.indexOf('Diseño de portadas') !== -1 }
              onChange={(event: any) => { handleCheckbox(event) }} />

              <CheckBox 
              label={'Booktrailer'}
              name={'categories'}
              value={'Booktrailer'}
              checked={ selectedCategories && selectedCategories.indexOf('Booktrailer') !== -1 }
              onChange={(event: any) => { handleCheckbox(event) }} />
            </div>
          }

          <div className="mt-3">
            { formErrors && formErrors.area &&
              <p className="error">{ formErrors.area }</p>
            }
            
            { formErrors && formErrors.categories &&
              <p className="error">{ formErrors.categories }</p>
            }
          </div>
        </div>

      </div>

      <div className="row pb-5">
        <div className="col text-center">
          { formErrors && formErrors.form &&
            <p className="error">{ formErrors.form }</p>
          }
          <Button value="Guardar" className="btn-orange mx-auto" onClick={saveCourseData} iconRight={ saving ? 'loaderIcon' : '' } />
        </div>
      </div>
    </React.Fragment>
  )
}

export default CourseData;