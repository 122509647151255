import "./Login.scss";

import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import InputText from "../../components/InputText/InputText";
import Button from "../../components/Button/Button";
import logo from "../../assets/images/logo.svg";
import ls from "../../services/localStorage";
import validateToken from "../../services/validateToken";
import { postApiLogin } from "../../services/apiUser";

const Login = (): React.ReactElement =>{
  const token: (string | null) = ls.get("token");
  const navigate = useNavigate();
  const [username, setUserName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    if (token && validateToken.validate(token)) {
      navigate("/");
    }
  }, [validateToken, token, navigate]);

  const doLogin = (): void =>{
    const result: object = postApiLogin({
      auth_credentials : {
        email: username,
        password: password
      }
    }).then((response: any) => {
      const token = response.token;
      ls.set("token", token);
      navigate("/");
    })
    .catch((error: any) => {
      setErrorMessage(error.message)
    });
  }

  return(
    <React.Fragment>
      <div id="login-container">
        <div className="container">
          <div className="row">
            <div className="col text-center">
              <h1 className="text-center">Bookolicos CMS</h1>
              <img src={logo} className="logo" />
              <InputText 
                placeholder={'Introduce tu email'}
                onChange={ (event: any) => { setUserName(event.target.value) } }
              />
              <InputText 
                placeholder={'Introduce tu contraseña'}
                type="password"
                onChange={ (event: any) => { setPassword(event.target.value) } }
              />
              { errorMessage && errorMessage !== '' ?
                <p className="error">{ errorMessage }</p>
              : null }
              <Button value="Entrar" className={'btn-orange m-auto mt-3'} onClick={doLogin} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Login;