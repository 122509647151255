import "./Ventas.scss";

import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Menu from "../../components/Menu/Menu";
import { MenuProps } from "../../components/Menu/MenuTypes";
import { defaultMenuProps } from "../../constants/constants";
import { getApiSales } from "../../services/apiSale";
import { types } from "../../constants/constants";
import { TableProps } from "../../components/Table/TableTypes";
import Table from "../../components/Table/Table";
import Button from "../../components/Button/Button";
import dayjs from "dayjs";
import 'dayjs/locale/es' // import locale


const Ventas = (): React.ReactElement =>{  
  const navigate = useNavigate();
  const [menuProps, setMenuProps] = useState<MenuProps>(defaultMenuProps);
  const [sales, setSales] = useState<TableProps['items']>([]);
  const [limit, setLimit] = useState<number>(types.PAGE_SIZE);
  const [offset, setOffset] = useState<number>(0);
  const [totalSales, setTotalSales] = useState<number>(0);
  const [actions, setActions] = useState<TableProps["actions"]>([]);
  const [sortDirection, setSortDirection] = useState<number>(-1);
  const [sortBy, setSortBy] = useState<string>('created_at');

  useEffect(() => {
    getSales();

    setActions([
      /*{
        name : 'Editar',
        onClick : editAction,
        icon : 'pencil' 
      },
      {
        name : 'Borrar',
        onClick : deleteAction,
        icon : 'deleteIcon' 
      }*/
    ])
  }, []);

  useEffect(() => {
    getSales();
  }, [sortBy, sortDirection]);

  const getSales = async (): Promise<void> => {
    const result: object = getApiSales(
      {
        limit: limit,
        offset: offset,
        sortBy: sortBy,
        sortDirection: sortDirection
      }
    ).then(async (response: any) => {
      setTotalSales(response.total)

      console.log(response.sales)

      const resultsSales: TableProps['items'] = await Promise.all(response.sales && response.sales.map((sale: any, index: number) =>{
        return [
          { name: '#', value : index + 1 },
          { name: 'Id', value : sale._id },
          { name: 'Stripe id', value : sale.payment_intent},
          { name: 'Título', value : sale.book.title, sortable: true, index: 'book.title' },
          { name: 'Autor', value : `${sale.seller.firstName} ${sale.seller.lastName}`},
          { name: 'Comprador', value : `${sale.customer.firstName} ${sale.customer.lastName}`},
          { name: 'Importe', value : sale.amount ? `${(sale.amount/100).toString().replace('.', ',')}€` : '--', sortable: true, index: 'price', className: 'text-center' },
          { name: 'Descargas', value : sale.downloads ? sale.downloads : 0, sortable: true, index: 'downloads', className: 'text-center' },
          { name: 'Fecha venta', value : dayjs(sale.created_at).format('DD/MM/YYYY'), sortable: true, index: 'created_at', className: 'text-center' }
        ]
      }));
      setSales(resultsSales);
    })
    .catch((error: any) => {
      console.log(error.message);
      setSales([])
    });
  }

  const loadMoreSales = async (): Promise<void> => {
    const offsetPage = offset + limit;
    
    const result: object = getApiSales(
      {
        limit: limit,
        offset: offsetPage,
        sortBy: sortBy,
        sortDirection: sortDirection
      }
    ).then(async (response: any) => {
      const resultsSales: TableProps['items'] = await Promise.all(response.sales && response.sales.map((sale: any, index: number) =>{
        return [
          { name: '#', value : index + offsetPage + 1 },
          { name: 'Id', value : sale._id },
          { name: 'Stripe id', value : sale.payment_intent},
          { name: 'Título', value : sale.book.title, sortable: true, index: 'book.title' },
          { name: 'Autor', value : `${sale.seller.firstName} ${sale.seller.lastName}`},
          { name: 'Comprador', value : `${sale.customer.firstName} ${sale.customer.lastName}`},
          { name: 'Importe', value : sale.amount ? `${(sale.amount/100).toString().replace('.', ',')}€` : '--', sortable: true, index: 'price', className: 'text-center' },
          { name: 'Descargas', value : sale.downloads ? sale.downloads : 0, sortable: true, index: 'downloads', className: 'text-center' },
          { name: 'Fecha venta', value : dayjs(sale.created_at).format('DD/MM/YYYY'), sortable: true, index: 'created_at', className: 'text-center' }
        ]
      }));
      
      const arraySales = sales.concat(resultsSales)
      setOffset(offsetPage);
      setSales(arraySales);
    })
    .catch((error: any) => {
      console.log(error.message);
      setSales([])
    });
  }

  const editAction = (id: string): void => {
    navigate(`/ventas/${ id }`);
  }

  const deleteAction = (id: string): void => {
    alert('delete');
  }

  const doSortBy = (field : string): void => {
    setOffset(0)
    setSortBy(field)
    setSortDirection(sortDirection === 1 ? -1 : 1)
  }
  
  return(
    <React.Fragment>
      <div id="menu">
        <Menu items={ menuProps.items } />
      </div>

      <div id="app-content">
        <header className="table-header mb-4">
          <h1>Ventas</h1>
          <span>Total registros: { totalSales }</span>
        </header>
        { sales && sales.length ?
          <Table items={ sales } actions={actions} doSortBy={doSortBy} />
        :
          <p>No hay ventas</p>
        }

        { totalSales > sales.length ?
          <div className="row mt-4">
            <div className="col-12 col-md-4 offset-md-4">
              <Button className={'btn-white m-auto'} value={'Cargar más'} onClick={ () => { loadMoreSales() } } />
            </div>
          </div>
        : null }
      </div>
    </React.Fragment>
  )
}

export default Ventas;