import React from 'react';
import './App.scss';

import { Routes, Route, Navigate } from 'react-router-dom';
import ls from "./services/localStorage";
import validateToken from "./services/validateToken";

import Clientes from './containers/Clientes/Clientes';
import Cursos from './containers/Cursos/Cursos';
import CrearCurso from './containers/CrearCurso/CrearCurso';
import EditarCurso from './containers/EditarCurso/EditarCurso';
import EditarCliente from './containers/EditarCliente/EditarCliente';
import Libros from './containers/Libros/Libros';
import Login from './containers/Login/Login';
import NotFound from './containers/NotFound/NotFound';
import Ventas from './containers/Ventas/Ventas';
import VentasCursos from './containers/VentasCursos/VentasCursos';

const AuthWrapper = ({ children }: {children : React.ReactElement}): React.ReactElement => {
  const token = ls.get("token");

  if (validateToken.validate(token ? token : '')) {
    const user: ({ role: string } | null) = validateToken.getUser(token ? token : '')
    if(user && user.role === 'admin'){
      return children;
    }else{
      return <Navigate to="/login"></Navigate>;
    }
  } else {
    return <Navigate to="/login"></Navigate>;
  }
};

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Navigate to='/clientes' />} />
        <Route path="/clientes" element={ <AuthWrapper><Clientes /></AuthWrapper> } />
        <Route path="/clientes/:clienteId" element={ <AuthWrapper><EditarCliente /></AuthWrapper> } />
        <Route path="/cursos" element={ <AuthWrapper><Cursos /></AuthWrapper> } />
        <Route path="/cursos/crear" element={ <AuthWrapper><CrearCurso /></AuthWrapper> } />
        <Route path="/cursos/:cursoId" element={ <AuthWrapper><EditarCurso/></AuthWrapper> } />
        <Route path="/libros" element={ <AuthWrapper><Libros /></AuthWrapper> } />
        <Route path="/login" element={ <Login /> } />
        <Route path="/ventas" element={ <AuthWrapper><Ventas /></AuthWrapper> } />
        <Route path="/ventas-cursos" element={ <AuthWrapper><VentasCursos /></AuthWrapper> } />
        <Route path='*' element={ <NotFound /> } />
      </Routes>
    </div>
  );
}

export default App;