import "./CheckBox.scss";

import React, { useState } from "react";
import { CheckBoxProps } from "./CheckBoxData";

function CheckBox(props: CheckBoxProps) {
  const [value, setValue] = useState<string>(props.value ? props.value : '');

  const handleChange = (event: any): void => {
    props.onChange(event)
    setValue(event.currentTarget.value)
  }

  return(
    <div className={`check-box`}>
      { props.checked === true ?
        <input value={value} type={'checkbox'} onChange={handleChange} className={`check-box__input`} checked />
      :
        <input value={value} type={'checkbox'} onChange={handleChange} className={`check-box__input`} />
      }

      { props.label && <label className="check-box__text">{props.label}</label> }
    </div>
  );
}

export default CheckBox