import "./NotFound.scss";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Menu from "../../components/Menu/Menu";
import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import { defaultMenuProps } from "../../constants/constants";
import { MenuProps } from "../../components/Menu/MenuTypes";


const NotFound = (): React.ReactElement =>{  
  const navigate = useNavigate();
  const [menuProps, setMenuProps] = useState<MenuProps>(defaultMenuProps);
  
  return(
    <React.Fragment>
      <div id="menu">
        <Menu items={ menuProps.items } />
      </div>

      <div id="app-content">
        <section className="not-found">
          <img
            className="not-found__img"
            src="/images/404pageNotFound.png"
            alt="not-found"
          />
          <div className="not-found__section-text">
            <Icon name="face" size="large" color="principal" />
            <h1 className="not-found__title">Oh no...</h1>
            <p className="not-found__text">
              ¡Lo sentimos! Parece que no encontramos la página que solicitaste.
            </p>
            <Button className={"btn-orange"} value={"Volver a la home"} onClick={() => { navigate('/') }} />
          </div>
        </section>
      </div>
    </React.Fragment>
  )
}

export default NotFound;