import "./Avatar.scss";
import { AvatarDataProps } from "./AvatarDataTypes";

function Avatar(props: AvatarDataProps) {
  const sizeClass = props.size ? `avatar__${props.size}` : 'avatar__s';

  return (
    <span className={'avatar ' + sizeClass + ' ' + props.className}>
      { props.user.image ?
        <img src={props.user.image} />
      :
        <span>
          { props.user ? props.user.firstName.charAt(0) : 'B' }
          { props.user ? props.user.lastName.charAt(0) : '' }
        </span>
      }
    </span>
  );
}

export default Avatar;