import React from "react";
import "./Icon.scss";
import icons from "../../styles/icons";
import { IconProps } from "./IconTypes";

const Icon = ( IconProps: IconProps ): React.ReactElement => {
  const Icon = icons[IconProps.name as keyof typeof icons];
  const sizeClass = `icon--${IconProps.size}`;
  const backgroundClass = `icon--background-${IconProps.background}`;
  const colorClass = `icon--color-${IconProps.color}`;
  const borderClass = `icon--${IconProps.border}`;

  return (
    <div
      className={`icon ${sizeClass} ${IconProps.background ? backgroundClass : ""} ${
        IconProps.className ? IconProps.className : ""
      } ${IconProps.border ? borderClass : ""}`}
    >
      {IconProps.color ? (
        <Icon
          width="100%"
          height="100%"
          className={colorClass}
          onClick={IconProps.onClick}
        />
      ) : (
        <Icon width="100%" height="100%" onClick={IconProps.onClick} />
      )}
    </div>
  );
};

export default Icon;
