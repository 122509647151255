import "./EditarCliente.scss";

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Menu from "../../components/Menu/Menu";
import { MenuProps } from "../../components/Menu/MenuTypes";
import { defaultMenuProps } from "../../constants/constants";
import UserData from "../../components/User/UserData/UserData";
import { getApiUser } from "../../services/apiUser";
import { types } from "../../constants/constants";
import Button from "../../components/Button/Button";
import dayjs from "dayjs";
import 'dayjs/locale/es' // import locale
import { UserDataProps } from "../../components/User/UserData/UserDataTypes";
import Avatar from "../../components/User/Avatar/Avatar";


const Clientes = (): React.ReactElement =>{  
  const navigate = useNavigate();
  const urlParams = useParams()
  const [menuProps, setMenuProps] = useState<MenuProps>(defaultMenuProps);
  const [userData, setUserData] = useState<UserDataProps>({user:{firstName: '', lastName: ''}});
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    getUser({ _id: urlParams.clienteId})
  }, []);

  const getUser = async (attributes: any): Promise<void> => {
    const result: object = getApiUser(
      {
        _id: attributes._id
      }
    ).then(async (response: any) => {
      const userCategories: string[] = await Promise.all(response.categories.map((category: any) => { return category.tag }));

      setUserData({
        user: {
          _id : response._id,
          firstName: response.firstName,
          lastName: response.lastName,
          email: response.email,
          password: response.password,
          state: response.state,
          created_at: response.created_at,
          role: response.role,
          categories: userCategories,
          numBooks: response.books.length,
          stripe_account: response.stripe_account,
          profilePic: response.profilePic
        }
      })

      setLoaded(true)
    })
    .catch((error: any) => {
      console.log(error.message);
    });
  }
  
  return(
    <React.Fragment>
      <div id="menu">
        <Menu items={ menuProps.items } />
      </div>

      <div id="app-content">
        <header className="table-header mb-4">
          <h1>Editar cliente</h1>
          <span></span>
        </header>

        { loaded && 
          <div className="row">
            <div className="col-lg-7 pe-lg-5">
              <UserData user={userData.user} getUser={getUser} />
            </div>

            <div className="col-lg-5 ps-lg-5" id="more-data-card">
              <h4 className="mb-3">Más datos del usuario</h4>
              
              <div className="mb-4 align-items-start">
                { userData.user.profilePic ?
                  <Avatar size="l" user={{ firstName: userData.user.firstName, lastName: userData.user.lastName, image: userData.user.profilePic }} className="m-0" />
                :
                  <Avatar size="l" user={{ firstName: userData.user.firstName, lastName: userData.user.lastName }} className="m-0" />
                }
              </div>
              
              <p className="m-0 mb-1">
                <strong>Registrado el: </strong> { dayjs(userData.user.created_at).format('DD/MM/YYYY') }
              </p>

              <p className="m-0 mb-1">
                <strong>Interéses: </strong> { userData.user.categories?.toString().replace(',',', ') }
              </p>

              { userData.user.numBooks && (
                <p className="m-0 mb-1">
                  <strong>Libros publicados: </strong> { userData.user.numBooks }
                </p>
              )}

              <p className="m-0 mb-1">
                <strong>Cuenta Stripe: </strong> { userData.user.stripe_account ? userData.user.stripe_account : 'No tiene cuenta' }
              </p>
            </div>
          </div>
        }
      </div>
    </React.Fragment>
  )
}

export default Clientes;